
import checkSuspendWebsite from '~/mixins/pages/login/checkSuspendWebsite'
export default {
    name: 'Login',
    mixins: [checkSuspendWebsite],
    middleware: 'auth',
    methods: {
        doClickOutsize() {
            this.$router.push('/')
        },
    },
}
